<!-- eslint-disable -->
<template>
  <v-container id="dashboard" fluid tag="section">
  <base-material-card icon="mdi-package-variant-closed" title="Liste des entreprises" class="px-5 py-3">
  <template v-slot:heading>
    <v-icon @click="reloadItems">mdi-reload</v-icon>
  </template>
  <!--
    @on-column-filter="onColumnFilter"
    @on-page-change="onPageChange"
    @on-per-page-change="onPerPageChange"
    @on-sort-change="onSortChange"
    :sort-options="{enabled: true, initialSortBy: {field: 'date', type: 'desc'}}"
    :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200]}"
    mode="remote"
   -->
   <b-modal v-model="showLoginModal" @hidden="handleModalHide" hide-footer size="lg">
      <template #modal-title>
        Confirmation
      </template>
      <v-container class="py-0">
        <v-row>
          <v-col cols="12" md="12">
            <b-card title="Voulez vraiment accepter ce vendeur?">
            </b-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn @click="confirmAction" style="background-color:#4caf50;border-color:#4caf50" class="mr-3">
              Confirmer
            </v-btn>
            <v-btn @click="showLoginModal=false" style="background-color:#dc3545;border-color:#dc3545" class="mr-0">
              Annuler
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </b-modal>
    <b-modal v-model="showProviderModal" @hidden="handleModalHide" hide-footer size="sm">
      <template #modal-title>
        Statistiques de commerçant
      </template>
      <b-card bg-variant="success" text-variant="white" header="Livrée" class="text-center mb-3">
        <span class="float-left">{{totalStats.total_delivered}}</span>
        <span class="float-right">{{(totalStats.total_delivered*100/totalStats.total).toFixed(2).toString()+' %'}}</span>
      </b-card>
      <b-card bg-variant="danger" text-variant="white" header="Returned" class="text-center">
        <span class="float-left">{{totalStats.total_returned}}</span>
        <span class="float-right">{{(totalStats.total_returned*100/totalStats.total).toFixed(2).toString()+' %'}}</span>
      </b-card>
    </b-modal>
    <b-modal v-model="showEditModal" @hidden="handleModalHide" hide-footer size="md">
      <template #modal-title>
        Changement des informations de {{packageDetail.name}}
      </template>
      <b-form @submit.prevent="adminChangeInfo">
        <v-row>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.email" label="Email" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.password" label="Mot de passe" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.name" label="Nom" class="purple-input"/>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field required v-model="packageDetail.phone" label="Téléphone" class="purple-input"/>
        </v-col>
        </v-row>
        <v-col cols="12" class="text-right">
          <v-btn type="submit" style="background-color:#4caf50" class="mr-0">
            Confirmer
          </v-btn>
        </v-col>
        </b-form>
    </b-modal>
    <!-- @on-row-click="rowClick" -->
    <vue-good-table
      max-height="600px"
      :columns="columns"
      :isLoading.sync="isLoading"
      :rows="rows"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200]}"
      style-class="vgt-table condensed bordered tableOne"
    >
    <template slot="loadingContent">
        <div class="spinner spinner-primary mx-auto my-auto"></div>
    </template>
    <template slot="column-filter" slot-scope="props">
      <b-form-datepicker v-if="props.column.filterOptions.customFilterDate" @input="handleCustomFilterDate"></b-form-datepicker>
    </template>
    <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'password'">
          <b-button @click="copyPassword(props.row)" v-b-tooltip.hover :title="copyMessage" id="password-btn" style="background-color:#4caf50;color:white;" >
            <span>{{props.row.password}}</span>
          </b-button>
          <v-icon @click="showEdit(props.row)" style="color:blue;margin-left:10px;float:right">mdi-square-edit-outline</v-icon>
        </span>
        <span v-else-if="props.column.field == 'approved_value'">
          <v-icon @click="goToPost(props.row)" style="color:blue;margin-left:10px;float:right">mdi-open-in-new</v-icon>
          <span v-if="props.row.approved==null">
            <span style="color:#ffa700">{{props.row.approved_value}}</span>
            <v-icon id="action-btn" @click="prepareAction(props.row,'refuse')" style="color:#dc3545;margin-left:10px;float:right">mdi-close-circle</v-icon>
            <v-icon id="action-btn" @click="prepareAction(props.row,'accept')" style="color:#4caf50;margin-left:10px;float:right">mdi-check-bold</v-icon>
          </span>
          <span v-if="props.row.approved==true">
            <span style="color:green">{{props.row.approved_value}}</span>
            <v-icon id="action-btn" @click="prepareAction(props.row,'refuse')" style="color:#dc3545;margin-left:10px;float:right">mdi-close-circle</v-icon>
          </span>
          <span v-if="props.row.approved==false">
            <span style="color:red">{{props.row.approved_value}}</span>
            <v-icon id="action-btn" @click="prepareAction(props.row,'accept')" style="color:#4caf50;margin-left:10px;float:right">mdi-check-bold</v-icon>
          </span>
        </span>
        <span v-else>
          {{props.row[props.column.field]}}
        </span>
    </template>
    </vue-good-table>
  </base-material-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
export default {
  name: 'CenterDashboard',
  data () {
    return {
      // searchTerm: '',
      copyMessage: 'Cliquer pour copier',
      communues: [],
      selectedWilaya: {
        name:null,
        communues: []
      },
      statusList: ['En attente', 'Accepté', 'Réfusé'],
      selectedCommunue: null,
      showPackageModal: false,
      showHisotryModal: false,
      isLoading: false,
      totalRows: 0,
      tableFilters: {
        approved: ''
      },
      outgoingTableFilters: {
        id: null,
        name: null,
        status: null,
        from_wilaya: null,
        date: null
      },
      history: {
        wilaya: null,
        communue: null,
        tracking_id: null,
        show: false,
        message: null,
        creation_date: {
          date: null,
          time: null
        }
      },
      providerDetail: null,
      columns:[
        {
          label: 'Nom complet',
          field: 'name',
          width: '200px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer le nom',
            // trigger: 'enter',
          }
        },
        {
          label: 'Email',
          field: 'email',
          width: '200px',
          filterOptions: {
            enabled:true,
            placeholder: 'Entrer l\'email',
            // trigger: 'enter',
          }
        },
        {
          label: 'Mot de passe',
          field: 'password',
          width: '200px',
          filterOptions: {
            enabled:false,
          }
        },
        {
          label: 'Numéro de téléphone',
          field: 'phone',
          width: '200px',
          filterOptions: {
            enabled:true,
            placeholder: 'Enter le numéro',
            // trigger: 'enter',
          }
        },
        // {
        //   label: 'Poste',
        //   field: 'related_post',
        //   width: '200px',
        //   filterOptions: {
        //     enabled: true,
        //     placeholder: 'Choisir la poste',
        //     // trigger: 'enter',
        //   },
        // },
        {
          label: 'Action',
          field: 'approved_value',
          width: '150px',
          filterOptions: {
            enabled:true,
            placeholder: 'Choisir l\'action',
            filterFn: this.columnFilterFn,
            // trigger: 'enter',
          }
        },
      ],
      rows:[],
      outgoingRows: [],
      showLoginModal: false,
      preparedRow: null,
      preparedAction: null,
      showProviderModal: false,
      showEditModal:false,
      packageDetail:{},
      totalStats: {
        total_delivered: 0,
        total_ongoing: 0,
        total_onhold: 0,
        total_returned: 0,
        returned_rate: 0,
      }
    }
  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    // this.getPostCenters()
    this.loadProviders()
    // this.columns[4].filterOptions.filterDropdownItems = this.statusList
    this.$set(this.columns[5].filterOptions, 'filterDropdownItems', this.statusList);
  },
  methods: {
    adminChangeInfo() {
      axios.post('admin-change-profile', {
        auth_token: localStorage.getItem('auth_token'),
        user_info: {
          auth_token: this.packageDetail.auth_token,
          email:this.packageDetail.email,
          password:this.packageDetail.password,
          name: this.packageDetail.name,
          phone: this.packageDetail.phone,
          user_type: 'provider'
        }
      }).then(response => {
        this.showEditModal = false
        this.loadProviders()
      })
    },
    showEdit(row) {
      this.packageDetail = row
      this.showEditModal = true
    },
    goToPost(packageDetail) {
      localStorage.setItem('admin_auth_token',localStorage.getItem('auth_token'))
      localStorage.setItem('auth_token',packageDetail.auth_token)
      window.location.replace("dashboard");
    },
    reloadItems() {
      this.isLoading = true
      this.loadProviders()
    },
    copyPassword(row) {
      this.$clipboard(row.password);
      this.copyMessage = 'Copié!'
      setTimeout(() => {
        this.copyMessage = 'Cliquer pour copier'
      }, 2000);
    },
    getPostCenters() {
      axios.post('get-post-centers').then(response => {
        if (response.data.status == 'success') {
          let wilayas = []
          response.data.data.forEach(element => {
            wilayas.push(element.name)
          });
          this.$set(this.columns[4].filterOptions, 'filterDropdownItems', wilayas);
          // this.columns[3].filterOptions.filterDropdownItems = wilayas
        }
      })
    },
    prepareAction(row,action) {
      this.preparedRow = row
      this.preparedAction = action
      this.showLoginModal = true
    },
    confirmAction() {
      if (this.preparedAction == 'accept') this.acceptProvider(this.preparedRow)
      else if (this.preparedAction == 'refuse') this.refuseProvider(this.preparedRow)
      this.showLoginModal = false
    },
    handleModalHide(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.showEditModal = false
      this.history.show = false
    },
    hideModal(params) {
      this.showPackageModal = false
      this.showHisotryModal = false
      this.history.show = false
    },
    loadProviders() {
      axios.post('admin-providers', {
        auth_token: localStorage.getItem('auth_token'),
        filters: this.tableFilters
      }).then(response => {
        if (response.data.status == 'success')
          this.rows = response.data.data
      })
    },
    rowClick(params) {
      // if (!this.showOrderWithDuplicates && params.event.target.localName !== "input" && params.event.target.localName !== "select" && params.event.target.localName !== "option" && params.event.target.className !== "vgt-checkbox-col" && params.event.target.className !== "dropdown-item" && params.event.target.type !== "button" && params.event.target.tagName !== "A") {
      this.providerDetail = params.row
      if (params.event.target.id == "action-btn") {
      }
      else if (params.event.target.id == 'password-btn' || params.event.target.parentNode.id == 'password-btn' || params.event.target.parentNode.parentNode.id == 'password-btn') {
        this.copyPassword(params.row)
      }
      else {
        this.showProviderModal = true
        axios.post('admin-stats', {
          auth_token : localStorage.getItem('auth_token'),
          provider_email: this.providerDetail.email
        }).then(response => {
          if (response.data.status == 'success') this.totalStats = response.data.data
        })
      }
    },
    changeStatus(row, tableType) {
      axios.post('center-change-status', {
        auth_token: localStorage.getItem('auth_token'),
        tracking_id: row.tracking_id,
        new_status: row.status
      }).then(response => {
        if (response.data.status == 'success') {
          if (tableType == 'incoming') this.loadIncomingItems()
          else if (tableType == 'outgoing') this.loadOutgoingItems()
        }
      })
    },
    onColumnFilter(params) {
      console.log(params)
      this.tableFilters = params.columnFilters
      if (params.columnFilters.approved_value == this.statusList[0]) this.tableFilters.approved = null
      else if (params.columnFilters.approved_value == this.statusList[1]) this.tableFilters.approved = true
      else if (params.columnFilters.approved_value == this.statusList[2]) this.tableFilters.approved = false
      else this.tableFilters.approved = ''
      this.loadProviders()
    },
    onSortChange(params) {
      let t = params[0].type
      let f = params[0].field
      this.rows.sort(function(a, b) {
        if (t == 'asc') {
          if ( a[f] < b[f] ){
            return -1;
          }
          if ( a[f] > b[f] ){
            return 1;
          }
        }
        else if (t == 'desc') {
          if ( a[f] < b[f] ){
            return 1;
          }
          if ( a[f] > b[f] ){
            return -1;
          }
        }
      });
    },
    onPageChange(params) {
      console.log(params)
    },
    onPerPageChange(params) {
      console.log(params)
    },
    // this.$set(this.columns[5].filterOptions, 'filterValue', '');
    refuseProvider(row) {
      axios.post('admin-refuse-provider', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = false
          this.loadProviders()
        }
      })
    },
    acceptProvider(row) {
      axios.post('admin-accept-provider', {
        auth_token: localStorage.getItem('auth_token'),
        email: row.email
      }).then(response => {
        if (response.data.status == 'success') {
          row.approved = true
          this.loadProviders()
        }
      })
    },
  },
}
</script>
<style>
.vgt-responsive {
  min-height: 450px;
}
</style>
